import React, { createRef, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { countries } from '../Utils/Countries';
import { FREE_TRIAL_VERIFY } from './constants';

const Verify = ({ auth, freeTrial, onSubmit, t }) => {
  const selectRef = createRef();
  const [number, setNumber] = useState('');
  const [code, setCode] = useState('');

  function onFocus() {
    Array.from(selectRef.current.options).forEach((opt) => {
      // eslint-disable-next-line no-param-reassign
      opt.textContent = `${opt.getAttribute('data-country')} ${opt.value}`;
    });
  }

  function onChange(val) {
    Array.from(selectRef.current.options).forEach((opt) => {
      // eslint-disable-next-line no-param-reassign
      opt.textContent = opt.value;
    });
    selectRef.current.blur();
    setCode(val);
  }

  useEffect(() => {
    setCode(countries.find((c) => c.code === auth.Location).dial_code);
  }, [auth]);

  return (
    <div className="step verify">
      <div className="phone-verification form-field-wrapper">
        <div className="item item-code">
          <label htmlFor="code">{t('Phone number')}</label>
          <div className="styled-select">
            <select
              id="code"
              ref={selectRef}
              onFocus={onFocus}
              onChange={(e) => onChange(e.target.value)}
              onBlur={(e) => onChange(e.target.value)}
              value={code}
            >
              {countries.map((c) => (
                <option key={c.name} value={c.dial_code} data-country={c.name}>
                  {c.dial_code}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="item item-country form-field-wrapper">
          <input
            type="text"
            onChange={(e) => setNumber(e.target.value)}
            value={number}
            id="number"
            required
            placeholder="xxxxxxx"
          />
        </div>
      </div>
      <div className="spacer" />
      <button
        type="button"
        className="button"
        onClick={() => onSubmit(selectRef.current.value, number)}
        disabled={number.length < 5 || freeTrial.loading}
      >
        {t('Get verification code')}
      </button>
    </div>
  );
};

export default connect(
  (state) => ({
    auth: state.auth,
    freeTrial: state.freeTrial,
  }),
  (dispatch) => ({
    onSubmit: (code, number) => {
      dispatch({ type: FREE_TRIAL_VERIFY, code, number });
    },
  }),
)(withTranslation()(Verify));
